.babySpaServicesSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 200px 0;
  background-image: url("../../assets/BackgroundImages/backgrondBubbles.svg");
  background-size: 100%;
  background-repeat: round;
}
.babySpaServicesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  gap: 80px;
}
.babySpaServicesContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.babySpaServicesContentVariation {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.babySpaServiceCloudContainer {
  display: flex;
  height: 545px;
  z-index: 1;
}
.serviceDescriptionImage {
  width: 100%;
  height: 100%;
}
.babySpaServiceImage {
  display: flex;
  justify-content: flex-start;
  width: 40%;
  height: 100%;
}
.babySpaServiceImageVariation {
  display: flex;
  justify-content: flex-end;
  width: 40%;
  height: 100%;
}

.roundImageContainer {
  height: 100%;
  border-radius: 50%;
  position: relative;
  left: -50px;
}
.roundImageContainerTop200 {
  height: 100%;
  border-radius: 50%;
  position: relative;
  left: -50px;
}
.roundImageContainerVariation {
  height: 100%;
  border-radius: 50%;
  position: relative;
  right: -50px;
}
.babySpaServiceCloudContainerMobile {
  display: none;
}
.babySpaServiceCloudContainerMobileTraining {
  display: none;
}
.babySpaServiceVideo {
  width: 530px;
  height: 530px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  left: -100px;
}
.serviceDescriptionVideo {
  width: 100%;
  height: auto;
}
@media (max-width: 768px) {
  .babySpaServicesSection {
    margin: 50px 0;
    overflow: hidden;
  }
  .babySpaServicesContainer {
    align-items: center;
    width: 100%;
    gap: 10px;
    margin-top: 20px;
  }
  .babySpaServiceCloudContainer {
    display: none;
  }
  .babySpaServiceCloudContainerMobile {
    display: flex;
    height: 545px;
    z-index: 1;
  }

  .babySpaServiceCloudContainerMobileTraining {
    display: flex;
    height: 550px;
    z-index: 1;
    transform: scale(1.5);
  }
  .babySpaServicesContent {
    flex-direction: column;
  }
  .babySpaServicesContentVariation {
    flex-direction: column-reverse;
  }
  .babySpaServiceVideo {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    top: -160px;
    left: 100px;
  }
  .roundImageContainer {
    height: 100%;
    border-radius: 50%;
    position: relative;
    top: -130px;
    left: 120px;
  }
  .roundImageContainerTop200 {
    height: 100%;
    border-radius: 50%;
    position: relative;
    top: -200px;
    left: 120px;
  }
  .roundImageContainerVariation {
    height: 100%;
    border-radius: 50%;
    position: relative;
    top: -130px;
    left: -100px;
  }
  .babySpaServiceImage,
  .babySpaServiceImageVariation {
    width: 60%;
  }
}
