.heroContainer {
  position: relative;
  height: 90vh;
  width: 100%;
  background-image: url("../../assets/BackgroundImages/activeBabyHeroBackground.webp");
  background-size: cover;
  background-position: center;
  color: white;
}
.heroOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #77acfc;
  opacity: 25%;
}
.heroContent {
  position: absolute;
  top: 20%;
  left: 10%;
}
.heroContentTitles {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10px;
}
.heroButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 218px;
  height: 60px;
  border-radius: 8px;
  background-color: #0799b4;
  margin-top: 20px;
  cursor: pointer;
}
.bottomImage {
  position: absolute;
  bottom: -100px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 200px;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .heroContainer {
    height: 60vh;
  }
  .heroContent {
    top: 10%;
  }
  .heroContentTitles {
    width: 100%;
  }
  .bottomImage {
    height: 100px;
    margin-bottom: 50px;
  }
  .heroButton {
    width: 152px;
    height: 44px;
  }
}
