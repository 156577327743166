.faqSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 200px 0;
  gap: 50px;
  background-image: url("../../assets/BackgroundImages/backgrondBubbles.svg");
  background-size: 100%;
  background-position: center;
  background-repeat: round;
}
.faqSectionTitle {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.faqSectionAccordion {
  width: 60%;
  gap: 10px;
}
.faqItem {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}
.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;
}

.arrow {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}
.answer {
  margin-bottom: 20px;
}
.divider {
  height: 1px;
  background-color: #c7baaf8c;
  padding: 0 10px;
}
@media (max-width: 768px) {
  .faqSection {
    gap: 20px;
    margin: 50px 0;
  }
  .faqSectionTitle {
    gap: 10px;
  }
  .faqSectionAccordion {
    width: 90%;
    gap: 5px;
  }
  .arrow {
    width: 18px;
    height: 18px;
  }
}
