.benefitsSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: 200px 0;
}
.benefitsSectionContentDesktop {
  display: flex;
  width: 80vw;
  height: 80vh;
  align-items: center;
  justify-content: center;
}
.benefitsDesktopImage {
  background-image: url("../../assets/BackgroundImages/benefitsBgDesktop.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.benefitsSectionContentMobile {
  display: none;
}

@media (max-width: 768px) {
  .benefitsSection {
    margin: 50px 0;
  }
}
@media (max-width: 445px) {
  .benefitsSection {
    margin: 50px 0;
  }
  .benefitsSectionContentDesktop {
    display: none;
  }
  .benefitsSectionContentMobile {
    display: flex;
    width: 100%;
    height: 1200px;
  }
  .benefitsMobileImage {
    background-image: url("../../assets/BackgroundImages/benefitsBgMobile.webp");
    background-size: 100% 85%;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
  .benefitsMobileContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 48px;
  }
  .benefitsMobileContentTitle {
    display: flex;
    flex-direction: column;
    width: 70%;
    text-align: center;
    margin-top: 65%;
  }
  .benefitsMobileContentInfo {
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 20px;
  }
  .checkmarkIcon {
    width: 36px;
    height: 36px;
  }
  .benefitsMobileInfoItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
}
