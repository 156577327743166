.contactSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: 200px 0;
}

.contactContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  gap: 50px;
}

.contactInfoContainer {
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 50px;
}

.contactInfoDetails {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contactInformations {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.contactInfoTitles,
.contactInfoTime {
  display: flex;
  flex-direction: column;
}

.contactIcons {
  width: 32px;
  height: 32px;
}

.contactInfoTime {
  margin: 10px 0;
}

.mapContainer {
  display: flex;
  flex-direction: column;
  width: 40%;
  justify-content: flex-end;
}

.contactCloudContainer {
  position: relative;
  right: -150px;
  top: -250px;
  z-index: 1;
}

.contactCloud {
  position: absolute;
  top: 0;
  right: 0;
  background-image: url("../../assets/ContactCloud.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
}

.contactMapContainer {
  display: flex;
  margin-top: -100px;
  z-index: 2;
}

.map {
  width: 400px;
  height: 400px;
  border-radius: 24px;
}

.contactLink {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
.popupDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popupImg {
  width: 200px;
  height: 200px;
}
@media (max-width: 768px) {
  .contactSection {
    margin: 50px 0;
  }
  .contactContainer {
    flex-direction: column;
    gap: 10px;
  }
  .map {
    width: 80vw;
    height: 316px;
    border-radius: 24px;
  }
  .contactInfoContainer {
    width: 100%;
    gap: 20px;
  }
  .mapContainer {
    width: 100%;
    align-items: flex-end;
  }
  .contactCloudContainer {
    justify-content: flex-start;
    width: 200px;
    height: 200px;
    right: -40px;
    top: 20px;
  }
  .contactCloud {
    width: 100%;
    height: 129px;
  }
  .popupImg {
    width: 80px;
    height: 90px;
  }
}
