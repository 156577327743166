.aboutBabySpaSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
  background-image: url("../../assets/BackgroundImages/backgrondBubbles.svg");
  background-size: 100%;
  background-position: center;
  background-repeat: space;
}
.aboutBabySpaContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  gap: 50px;
}
.imageContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.image {
  background-image: url("../../assets/AboutBabySpa.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.textContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 20px;
  margin-top: -50px;
}
.mainHeadingAbout {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.contentAbout {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.ctaButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 218px;
  height: 60px;
  border-radius: 8px;
  background-color: #0799b4;
  margin-top: 20px;
  text-decoration: none;
  cursor: pointer;
}
@media (max-width: 768px) {
  .aboutBabySpaSection {
    margin: 50px 0;
    margin-top: 100px;
    background-repeat: round;
  }
  .aboutBabySpaContainer {
    flex-direction: column-reverse;
    align-items: center;
    width: 85%;
    gap: 20px;
  }
  .textContainer {
    width: 100%;
    gap: 10px;
  }
  .contentAbout {
    width: 100%;
  }
  .imageContainer {
    width: 100%;
  }
  .image {
    width: 100%;
    height: 327px;
  }
}
