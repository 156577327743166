.gallerySection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  gap: 20px;
  overflow: hidden;
}
.galleryHeadingContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.galleryStarsLeft .galleryStarsLeft {
  height: 88px;
  width: 48px;
}
.galleryStarsRight {
  transform: scaleX(-1);
}
.galleryHeading {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}
.carousel {
  width: 800px;
  overflow: hidden;
}
.carousel-slider {
  max-height: 600px !important;
}
.carousel img {
  object-fit: cover !important;
}

@media (max-width: 445px) {
  .carousel {
    width: 100%;
    overflow: hidden;
  }
}
