.aboutElaSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: 200px 0;
  background-image: url("../../assets/BackgroundImages/backgrondBubbles.svg");
  background-size: 100%;
  background-repeat: round;
}
.aboutElaContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  gap: 50px;
}
.imageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
}
.image {
  background-image: url("../../assets/Ela.jpeg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 330px;
}
.textContainer {
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 20px;
}
.mainHeadingAbout {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.contentAbout {
  display: flex;
  flex-direction: column;
  width: 80%;
}
@media (max-width: 768px) {
  .aboutElaSection {
    margin: 50px 0;
  }
  .aboutElaContainer {
    flex-direction: column;
    align-items: center;
    width: 85%;
  }
  .textContainer {
    width: 100%;
    gap: 10px;
    align-items: flex-start;
  }
  .contentAbout {
    width: 100%;
  }
  .imageContainer {
    width: 100%;
  }
  .image {
    width: 100%;
    height: 327px;
  }
}
