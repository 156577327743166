@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap");

:root {
  --white: #ffffff;
  --gray-100: #c2c2c2;
  --gray-200: #c4c4c4;
  --gray-300: #909090;
  --gray-400: #8f8f91;
  --gray-500: #4f4d5a;
  --blue: #77acfc;
  --mint: #0799b4;
  --backgroundColor: #f5f0ec;
}
.App {
  background-color: var(--backgroundColor);
  width: 100%;
}
/* Text Colors */
.bg-color {
  color: var(--backgroundColor);
}
.text-white {
  color: var(--white);
}
.text-gray-100 {
  color: var(--gray-100);
}
.text-gray-200 {
  color: var(--gray-200);
}
.text-gray-300 {
  color: var(--gray-300);
}
.text-gray-400 {
  color: var(--gray-400);
}
.text-gray-500 {
  color: var(--gray-500);
}
.text-blue {
  color: var(--blue);
}
.text-mint {
  color: var(--mint);
}

/* Typography */
.heading1 {
  font-family: "Comfortaa", sans-serif;
  font-size: 80px;
  font-weight: 400;
  line-height: 89px;
  letter-spacing: 0em;
}
.heading2 {
  font-family: "Comfortaa", sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: 0em;
}
.heading3 {
  font-family: "Comfortaa", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: 0em;
}
.subheading1 {
  font-family: "Comfortaa", sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.01em;
}
.label-large-medium {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.01em;
}
.label-large-regular {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.01em;
}
.label-medium-Comfortaa {
  font-family: "Comfortaa", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.04em;
}
.label-medium-Inter {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
}
.body-regular {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}
/* Styling */
.app-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 14px 104px;
  background-color: #ffffff;
  height: 100px;
}
.logo-container {
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.app-main-content {
  margin-top: calc(50px + 10px);
}
.uppercase-letters {
  text-transform: uppercase;
}
.cursor-pointer {
  cursor: pointer;
}
@media (max-width: 768px) {
  .app-header {
    padding: 14px 24px;
  }
  .heading1 {
    font-size: 48px;
    line-height: 54px;
  }

  .heading2 {
    font-size: 40px;
    line-height: 48px;
  }
  .heading3 {
    font-size: 32px;
    line-height: 40px;
  }
  .subheading1 {
    font-size: 24px;
    line-height: 32px;
  }
  .label-large-regular {
    font-size: 20px;
    line-height: 24px;
  }
  .label-medium-Inter {
    font-size: 14px;
  }
}
@media (max-width: 445px) {
  .heading3 {
    font-size: 24px;
    line-height: 28px;
  }
}
