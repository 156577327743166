.pricingListSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 200px 0;
  background-image: url("../../assets/BackgroundImages/backgrondBubbles.svg");
  background-size: 100%;
  background-repeat: round;
}
.pricingListContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 50px;
}
.pricingListGeneralServices {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}
.pricingListItems {
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 50px;
}
.pricingItemsTitle {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.item {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}
.divider {
  display: flex;
  height: 1px;
  flex-grow: 1;
  background-color: #dcd2ca;
}
.price {
  display: flex;
  width: 20%;
}
@media (max-width: 768px) {
  .pricingListSection {
    margin: 50px 0;
  }
  .pricingListContainer {
    width: 85%;
  }
  .pricingListGeneralServices {
    flex-direction: column;
    width: 100%;
    gap: 30px;
  }
  .pricingListItems {
    width: 100%;
    gap: 30px;
  }
}
