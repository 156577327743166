.footer {
  background-image: url("../../assets/BackgroundImages/footerBackground.png");
  background-size: cover;
  background-position: top;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 20px;
}
.footerContent {
  display: flex;
  flex-direction: row;
}
.footerLogo {
  display: flex;
  align-items: center;
  width: 60%;
}
.footerBrandLogo {
  width: 200px;
  height: 200px;
}
.footerServices {
  display: flex;
  flex-direction: row;
  width: 40%;
  margin-top: 20px;
}
.footerLinks {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 32px;
}
.footerSocials {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 32px;
}
.socialsLogoContainer {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.socialsLogo {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.footerDivider {
  height: 1px;
  background-color: #ffffff30;
}
@media (max-width: 768px) {
  .footerContent {
    flex-direction: column;
    gap: 32px;
  }
  .footerServices {
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }
}
