.navbar {
  position: relative;
}

.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar ul li {
  margin-right: 20px;
}

.navbar ul li:last-child {
  margin-right: 0;
}

.navbar ul li a {
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

.navbarToggle {
  display: none;
  cursor: pointer;
}

.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.menu a {
  text-decoration: none;
  color: #333;
}
.menuBar {
  display: block;
  width: 30px;
  height: 2px;
  background-color: #0799b4;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 1;
}
.closeBar1 {
  transform: rotate(45deg) translate(5px, 5px);
}

.closeBar2 {
  transform: rotate(-45deg);
}
.closeBar3 {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.menu.show {
  list-style-type: none;
  margin: 0;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  top: 120px;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
  animation: showMenuAnimation 1s ease-in-out 0s 1 normal forwards;
}
@keyframes showMenuAnimation {
  0% {
    opacity: 0;
    transform: translateX(250px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .navbar ul {
    display: none;
  }

  .navbarToggle {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}
